import { Classes } from '@blueprintjs/core';

export const formGroupStyle: any = (
  hasError: boolean,
  disableMarginBottom: boolean,
  rightAlignLabelInfo: boolean
) => ({
  [`&.${Classes.FORM_GROUP} label.${Classes.LABEL}`]: {
    fontFamily: 'body',
    fontSize: 'small',
    fontWeight: 'bold',
    mb: '8px'
  },
  [`&.${Classes.FORM_GROUP}.${Classes.INLINE} label.${Classes.LABEL}`]: {
    mt: '7px'
  },
  [`&.${Classes.FORM_GROUP}.${Classes.DISABLED} .${Classes.LABEL}, &.${Classes.FORM_GROUP}.${Classes.DISABLED} .${Classes.TEXT_MUTED}, &.${Classes.FORM_GROUP}.${Classes.DISABLED} &.${Classes.FORM_GROUP_SUB_LABEL}, &.${Classes.FORM_GROUP}${Classes.DISABLED} .${Classes.FORM_HELPER_TEXT}`]: {
    color: 'black !important'
  },
  [`&.${Classes.FORM_GROUP} .${Classes.FORM_HELPER_TEXT}`]: {
    fontFamily: 'body',
    fontSize: 'small',
    color: 'extendedRed100',
    mt: '8px'
  },
  [`&.${Classes.FORM_GROUP}`]: {
    mb: disableMarginBottom ? 0 : undefined
  },
  [`.${Classes.INPUT}`]: {
    fontFamily: 'body',
    fontSize: 'base'
  },
  '.helper-text-div': {
    display: 'flex',
    justifyContent: hasError ? 'space-between' : 'flex-end'
  },
  [`&.${Classes.FORM_GROUP} .${Classes.LABEL} .${Classes.TEXT_MUTED}`]: {
    float: rightAlignLabelInfo ? 'right' : undefined
  }
});
