import Button from 'components/v2/atomic/button/Button';
import Card from 'components/v2/atomic/card/Card';
import Icon from 'components/v2/atomic/icon/Icon';
import { IconNamesXSmall } from 'components/v2/atomic/icon/Icons';
import { contentful } from 'modules/contentful';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { RobotoFlex } from 'styles/theme';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import clientSide from 'utils/logger/client-side';

export interface CarouselCardProps {
  entryId: string;
  useGlobalStyle?: boolean;
}

type ContentfulResponse = {
  loading: boolean;
  error?: Error;
  value: {
    title: string;
    newsItems: Array<{
      fields: {
        description: string;
        image: {
          fields: {
            description: string;
            title: string;
            file: {
              contentType: string;
              details: {
                image: {
                  width: number;
                  height: number;
                };
                size: number;
              };
              fileName: string;
              url: string;
            };
          };
        };
        title: string;
        ctaButtonText?: string;
        ctaButtonUrl?: string;
      };
    }>;
  };
};

const CarouselCard = ({
  entryId,
  useGlobalStyle = false
}: CarouselCardProps) => {
  const carouselContent = useAsync(async () => {
    const response = await contentful.entry.get(entryId);
    return response.fields;
  }, []) as ContentfulResponse;
  // in this case the default ContentfulResponse is crazy generic and not helpful
  // without a significant amount of work, so we'll just cast it to the correct types

  if (carouselContent.error) {
    clientSide.error(
      'There was an error fetching carousel content from Contentful',
      carouselContent.error
    );
  }

  const [activeIndex, setActiveIndex] = useState(1);
  const [left, setLeft] = useState('0');
  const slideWidth = 100 / carouselContent?.value?.newsItems?.length;
  const breakpoint = useBreakpoint();

  return carouselContent.loading ||
    carouselContent.error ||
    carouselContent.value?.newsItems?.length === 0 ? null : (
    <Card
      id={`${carouselContent?.value?.title}_carousel`}
      accent
      accentColor="extendedBlue100"
      sxOverride={{
        h2: {
          fontFamily: useGlobalStyle
            ? "Georgia, 'CharterITCStd-Regular', 'Times New Roman', 'Times', serif"
            : RobotoFlex.style.fontFamily
        },
        '.content': {
          padding: useGlobalStyle ? '2rem 3rem' : '2rem'
        },
        flex: 1
      }}
    >
      <h2
        sx={{
          mb: '1rem',
          fontWeight: !useGlobalStyle ? 700 : undefined,
          marginBottom: useGlobalStyle ? '1em' : '1.5rem'
        }}
        data-testid={`${carouselContent?.value?.title}_title`}
      >
        {carouselContent?.value?.title}
      </h2>
      <div
        sx={{
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <ul
          sx={{ width: `${carouselContent?.value?.newsItems?.length * 100}%` }}
        >
          {carouselContent?.value?.newsItems?.map((item, index) => (
            <li
              sx={{
                left,
                width: `calc(${100 /
                  carouselContent?.value?.newsItems?.length}% - 0.5rem)`,
                float: 'left',
                position: 'relative',
                transition: 'all 0.3s ease-in-out',
                marginRight: '0.625rem',
                '&:last-child': {
                  marginRight: 0,
                  float: 'inherit',
                  width: '100%'
                }
              }}
              key={`slide-${item.fields?.title
                .toLowerCase()
                .replace(/ /g, '-')}`}
              className={index === activeIndex - 1 ? 'active' : undefined}
              data-testid={`slide-${item.fields?.title
                .toLowerCase()
                .replace(/ /g, '-')}`}
            >
              <div
                sx={{
                  display: 'flex',
                  flexDirection: breakpoint.isLessThanSmallMin
                    ? 'column-reverse'
                    : 'row',
                  gap: '2rem',
                  marginBottom: '1.5rem'
                }}
              >
                <div
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                    display: activeIndex - 1 !== index ? 'none' : 'flex'
                  }}
                >
                  <h3
                    sx={{
                      fontFamily: useGlobalStyle
                        ? "'myriad-pro', sans-serif"
                        : RobotoFlex.style.fontFamily
                    }}
                  >
                    {item.fields?.title}
                  </h3>
                  <p
                    sx={{
                      fontFamily: useGlobalStyle
                        ? "'myriad-pro', sans-serif"
                        : RobotoFlex.style.fontFamily
                    }}
                  >
                    {item.fields?.description}
                  </p>
                  {item.fields?.ctaButtonText && (
                    <Button
                      id={`call-to-action-btn_${index}`}
                      data-testid={`${carouselContent?.value?.title}_call-to-action-btn`}
                      variant="outline"
                      onClick={() => {
                        window.open(item.fields?.ctaButtonUrl, '_blank');
                      }}
                      color="blue"
                      sxOverride={{
                        fontFamily: useGlobalStyle
                          ? "'myriad-pro', sans-serif"
                          : RobotoFlex.style.fontFamily,
                        width: breakpoint.isLessThanSmallMin
                          ? '100%'
                          : 'max-content',
                        '> span': {
                          textAlign: 'left'
                        }
                      }}
                    >
                      {item.fields?.ctaButtonText}
                    </Button>
                  )}
                </div>
                {item.fields?.image && (
                  <img
                    data-testid={`${carouselContent?.value?.title}_image`}
                    sx={{
                      maxWidth: 'unset',
                      width: '200px',
                      alignSelf: breakpoint.isLessThanSmallMin
                        ? 'center'
                        : 'flex-start',
                      height: 'auto'
                    }}
                    src={`https:${item.fields?.image?.fields?.file?.url}`}
                    alt={`${item.fields?.image?.fields?.file?.fileName}_image`}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
        {carouselContent?.value?.newsItems?.length > 1 && (
          <div
            sx={{
              display: 'inline-flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => {
                const updatedIndex =
                  activeIndex === 1
                    ? carouselContent?.value?.newsItems?.length
                    : activeIndex - 1;
                setActiveIndex(updatedIndex);
                setLeft(`-${slideWidth * (updatedIndex - 1)}%`);
              }}
              id="left-navigation-button"
              isIconButton
              variant="simple"
              aria-label="carousel previous button"
              sx={{
                width: '0.5rem',
                height: '0.5rem',
                color: 'brandNavy100'
              }}
            >
              <Icon
                icon={IconNamesXSmall.ARROW_LEFT}
                sx={{
                  svg: {
                    color: 'brandNavy100'
                  }
                }}
              />
            </Button>
            <ul
              sx={{
                display: 'flex',
                justifyContent: 'center',
                listStyleType: 'none'
              }}
            >
              {carouselContent?.value?.newsItems?.map((item, index) => (
                <li
                  key={`indicator-${item.fields?.title
                    .toLowerCase()
                    .replace(/ /g, '-')}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ':last-child': {
                      marginRight: '0'
                    }
                  }}
                >
                  <Button
                    type="button"
                    id={`indicator-${index}`}
                    variant="simple"
                    sx={{
                      backgroundColor:
                        index + 1 === activeIndex ? 'brandNavy100' : 'grey80',
                      width: '0.5rem',
                      height: '0.5rem',
                      padding: 0,
                      borderRadius: '100%',
                      borderWidth: 0,
                      margin: '0 0.25rem',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease-in-out'
                    }}
                    onClick={() => {
                      const updatedIndex = index + 1;
                      setActiveIndex(updatedIndex);
                      setLeft(`-${slideWidth * (updatedIndex - 1)}%`);
                    }}
                    aria-label={`slide ${index} button`}
                  />
                </li>
              ))}
            </ul>
            <Button
              onClick={() => {
                const updatedIndex =
                  activeIndex === carouselContent?.value?.newsItems?.length
                    ? 1
                    : activeIndex + 1;

                setActiveIndex(updatedIndex);

                setLeft(`-${slideWidth * (updatedIndex - 1)}%`);
              }}
              isIconButton
              id="right-navigation-button"
              variant="simple"
              aria-label="carousel next button"
              sx={{
                width: '0.5rem',
                height: '0.5rem',
                color: 'brandNavy100'
              }}
            >
              <Icon
                icon={IconNamesXSmall.ARROW_RIGHT}
                sx={{
                  svg: {
                    color: 'brandNavy100'
                  }
                }}
              />
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default CarouselCard;
